import Vue from 'vue'
import App from './App.vue'
import router from "../router/index";
import ElementUI from 'element-ui'; // elementUI
import 'element-ui/lib/theme-chalk/index.css'; // elementUI 样式
import store from "./store"; // 引入vuex
import 'default-passive-events'
import { Message } from 'element-ui';

Vue.use(ElementUI);
Vue.config.productionTip = false

//、消息提示
Vue.prototype.$message = Message;

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')