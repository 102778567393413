import Vue from 'vue';
import VueRouter from 'vue-router'; //引入VueRouter
Vue.use(VueRouter); // 注册组件

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

//创建router实例对象，去管理一组一组的路由规则

/**
* 使用 webpack 的 require.ensure() 实现按需加载 这种情况下，多个路由指定相同的name，会合并打包成一个js文件
* 这里有三个参数
* 第一个参数是个数组，标明依赖的模块，这些会提前加载
* 第二个是回调函数，在这个回调函数里面的require的文件会被单独打包成一个chunk,不会和主文件打包在一起，这样就生成了两个chunk,第一次加载时只加载主文件，当点击时就会加载单独打包的chunk
* 第三个是一个字符串，用于定义打包的chunk名称
*/
const router = new VueRouter({
	routes: [
		{
			path: '/',
			name: 'login',
			component: resolve => require.ensure([], () => resolve(require('../src/view/login/index.vue')),
				'achieve')
		}
		, 
		{
			path: '/login',
			name: 'login',
			component: resolve => require.ensure([], () => resolve(require('../src/view/login/index.vue')),
				'achieve')
		}
		, 
		/* {
			path: '/',
			name: 'home', // 首页名称
			component: resolve => require.ensure([], () => resolve(require('../src/view/home/index.vue')),
				'home')
		}, */ 
		
		{
			path: '/home',
			name: 'home',
			component: resolve => require.ensure([], () => resolve(require('../src/view/home/index.vue')),
				'home')
		}, {
			path: '/achieve',
			name: 'achieve',
			component: resolve => require.ensure([], () => resolve(require('../src/view/achieve/index.vue')),
				'achieve')
		}
		, {
			path: '/update',
			name: 'update',
			component: resolve => require.ensure([], () => resolve(require('../src/view/update/index.vue')),
				'achieve')
		}
		, {
			path: '/ptupdate',
			name: 'ptupdate',
			component: resolve => require.ensure([], () => resolve(require('../src/view/update/pt_index.vue')),
				'achieve')
		}
		, {
			path: '/my',
			name: 'my',
			component: resolve => require.ensure([], () => resolve(require('../src/view/my/index.vue')),
				'achieve')
		}
	]
})

/***
* @description: 处理跳由跳转后不能滚动到页面顶部问题 
* @author: zhouhua
*/
router.beforeEach((to, from, next) => {
	// chrome
	document.body.scrollTop = 0
	// firefox
	document.documentElement.scrollTop = 0
	// safari
	window.pageYOffset = 0
	next()
})

//暴露router
export default router
