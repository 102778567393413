import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex)
// 导出并创建store
export default new Vuex.Store({
	// 存储状态
	state: {
		show: 0, // 状态
		user: null, //用户信息
		token: "", //token
		myWishlist:[],//我的关注
	},
	getters: {
		user(state) {
			if (!state.user || state.user.length <=0) {
				let userinfo = localStorage.getItem('userInfo');
				state.user = userinfo ? JSON.parse(userinfo) : null;
			}
			console.log('当前登录用户：',state.user);
			return state.user || {}
		},
		token(state) {
			if (!state.token || state.token.length <=0) {
				let token = localStorage.getItem('token');
				state.token = token;
			}
			return state.token
		},
		//我的关注
		myWishlist(state) {
			if (!state.myWishlist || state.myWishlist.length <=0) {
				try{
					let myWishlist = localStorage.getItem('myWishlist');
					state.myWishlist = myWishlist ? JSON.parse(myWishlist) : [];
				}catch(a){
					console.log(a)
				}
			}
			return state.myWishlist
		}

	},
	// 存储修改state状态的函数
	mutations: {
		showChange(state /* 上面state的数据 */ , value /* 事件触发的数据 */ ) {
			state.show = value
		},
		setUser(state, user) {
			localStorage.setItem("userInfo", JSON.stringify(user));
			state.user = user;
			console.log('用户缓存设置成功：',user);
		},
		setToken(state, token) {
			localStorage.setItem("token", token);
			state.token = token;
		},
		LOGOUT(state) {
			localStorage.clear();
			state.user = null;
			state.token = null
		},
		//我的关注
		setMyWishlist(state, myWishlist) {
			localStorage.setItem("myWishlist", JSON.stringify(myWishlist));
			state.myWishlist = myWishlist;
		},
	},
	// 响应组件动作
	actions: {},
})
