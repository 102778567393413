<template>
	<div>

		<Head ref="head"></Head><!-- 头部 -->
		<router-view></router-view>
		<Foot></Foot><!-- 底部 -->
	</div>
</template>

<script>
	import Head from './components/head/index.vue'
	import Foot from './components/foot/index.vue'
	export default {
		name: 'App',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				list: []
			}
		},
		// 侦听属性
		watch: {
			// 侦听属性可以监控字段也可以监控函数 这里监控函数
			route: {
				handler() {
					this.$nextTick(() => {
						console.log(this.$route.path, '数据');

					});
				},
			},
		},
		// 计算属性
		computed: {
			route() {
				// 创建一个方法 用于专门抓取this.$route.path
				return this.$route.path;
			},
		},
		methods: {
			/* 更新顶部数字 */
			updateBaseData(){
				this.$refs.head.initBaseData();
			}
		}
	}
</script>

<script>
// Vue 移动端禁止页面放大缩小
window.onload = function() {
    document.addEventListener('touchstart', function(event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    })
    document.addEventListener('gesturestart', function(event) {
      event.preventDefault()
    })
  }
</script>


<style >
	#app {
		font-family: sans-serif;
		/* font-family: "Montserrat", sans-serif; */
		/* font-family: Avenir, Helvetica, Arial, sans-serif; */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 60px;
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
	}

	body {
		margin: 0 !important;
		font-family: "Montserrat", sans-serif;
	}

	a {
		text-decoration: none;
		font-family: "Montserrat", sans-serif;
		color: #5acbf5;
	}
	
	.el-scrollbar >.el-scrollbar__bar {
        opacity: 1 !important;
    }


</style>
